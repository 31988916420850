import React from "react";
import Styles from "./hero.module.scss";
import HeroImg from "../../../assets/hero-img.png";
import { Grid, Image } from "semantic-ui-react";
import PrimaryBtn from "../../buttons/primaryBtn";
import SecondaryBtn from "../../buttons/secondaryBtn.tsx";
import { ReactComponent as DoubleArrowDownIcon } from "../../../assets/icons/double-arrow-down.svg";

export default function HeroSection() {
  return (
    <Grid centered relaxed columns="2" className={Styles.container}>
      <Grid.Row>
        <Grid.Column width="9" className={Styles.heroImgWrapper}>
          <Image
            data-aos="fade"
            data-aos-delay={200}
            data-aos-easing="linear"
            src={HeroImg}
          />
        </Grid.Column>
        <Grid.Column width="7" className={Styles.heroContent}>
          <h1 data-aos="fade-up" data-aos-offset="-200">
            Payments redefined for businesses
          </h1>
          <p data-aos="fade-up" data-aos-offset="-200" data-aos-delay={200}>
            A complete suite of technologies to accept payments (on or offline),
            secure payment data, and squelch fraud, PayInvert is payment
            reimagined for every business.
          </p>
          <div className={Styles.btnWrapper}>
            <PrimaryBtn link="https://merchant.dev.payinvert.com/sign-up">
              Get started
            </PrimaryBtn>
            <SecondaryBtn link="mailto:contact@payinvert.com">
              Contact us <DoubleArrowDownIcon />
            </SecondaryBtn>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
