import React from "react";
import PrimaryBtn from "../../components/buttons/primaryBtn";
import Styles from "./company.module.scss";
import { Grid, Image } from "semantic-ui-react";
// icons
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/double-arrow-down.svg";
// images
import CheckoutImg from "../../assets/checkout-frame.png";
import CreditCardImg from "../../assets/credit-card-payment.png";
import WalletCardImg from "../../assets/credit-wallet.png";

export default function Company() {
  return (
    <div className={Styles.container}>
      <div className={Styles.heroWrapper}>
        <h1 data-aos="fade-bottom">Founded by Innovators</h1>
        <p data-aos="fade-bottom" data-aos-delay={100}>
          PayInvert was founded by innovators who want a better payment system
          that is fast, easy, convenient and with no hidden charges. The aim has
          always been to eradicate long queues and time taken to make payments
          physically. We are providing a secure way to receive payments
          electronically from anyone anywhere in the world with a robust
          reporting system to help companies plan their growth significantly.
        </p>
        <PrimaryBtn>
          Read more <ArrowDownIcon />
        </PrimaryBtn>
      </div>
      <Grid centered stackable relaxed columns="2" className={Styles.ctaWrapper}>
        <Grid.Row>
          <Grid.Column className={Styles.imgWrapper}>
            <Image data-aos="zoom-out-up" src={CheckoutImg} />
            <Image
              data-aos="zoom-out-up"
              data-aos-delay={100}
              src={CreditCardImg}
            />
            <Image
              data-aos="zoom-out-up"
              data-aos-delay={200}
              data-aos-offset="-200"
              src={WalletCardImg}
            />
          </Grid.Column>
          <Grid.Column>
            <h2 data-aos="fade-bottom">
              PayInvert is trusted by multi-million dollar companies globally
            </h2>
            <p data-aos="fade-bottom" data-aos-delay={100}>
              We are glad to have created a real solution trusted by
              multi-million dollar companies globally, a solution that
              understands the market system and also compatible with all types
              of businesses be it a startup or other huge market players.
            </p>
            <p data-aos="fade-bottom" data-aos-delay={200}>
              Our mission is to change the payment gateway industry for better
              with a remittance initiative like no other. By using and sharing
              PayInvert, you can join us to take real action for a better
              economy and ensure that more money is collected from customers
              anywhere in the world successfully and reaches its intended
              destination securely.
            </p>
            <p data-aos="fade-bottom" data-aos-delay={300}>
              Collectively, PayInvert is here to make a huge difference in the
              financial industry.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
