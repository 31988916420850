import React, { useEffect, useState } from "react";
import { Grid, Image } from "semantic-ui-react";
import Styles from "./hero.module.scss";
import PrimaryBtn from "../../buttons/primaryBtn";
import SecondaryBtn from "../../buttons/secondaryBtn.tsx";
import { ReactComponent as DoubleArrowDownIcon } from "../../../assets/icons/double-arrow-down.svg";

export default function HeroSection({
  title,
  desc,
  img1,
  img2,
  img3,
  className,
  noSecondaryBtn,
}) {
  const [offsetY, setOffsetY] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);
  // set page scrolled
  const handleScroll = () => {
    if (typeof window !== "undefined") {
      setOffsetY(window.scrollY);
      setLastScrollY(window.scrollY);
    }
  };
  // listen to page scroll
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return window.removeEventListener("scroll", handleScroll);
    }
  }, [lastScrollY]);

  useEffect(() => {
    console.log(offsetY);
  }, [offsetY]);

  return (
    <Grid relaxed stackable columns="equal" className={Styles.container}>
      <Grid.Row>
        <Grid.Column width={8} verticalAlign="middle">
          <div className={Styles.content}>
            <h1 data-aos="fade-bottom">{title}</h1>
            <p data-aos="fade-bottom" data-aos-delay={50}>
              {desc}
            </p>
            <div
              data-aos="fade-bottom"
              data-aos-delay={100}
              className={Styles.btnWrapper}
            >
              <PrimaryBtn link="https://merchant.dev.payinvert.com/sign-up">
                Get started
              </PrimaryBtn>
              {!noSecondaryBtn && (
                <SecondaryBtn>
                  Learn more <DoubleArrowDownIcon />
                </SecondaryBtn>
              )}
            </div>
          </div>
        </Grid.Column>
        <Grid.Column className={Styles.heroImgContainer} width={8}>
          <div className={Styles.dashboardImgWrapper}>
            <Image
              data-aos="zoom-out-up"
              data-aos-offset="-200"
              src={img1}
              className={Styles.dashboard}
            />
            <Image
              style={{ transform: `translateY(${offsetY * 22}px)` }}
              data-aos="zoom-out-up"
              data-aos-delay={50}
              src={img2}
              className={Styles.summary}
            />
            <Image
              data-aos="zoom-out-up"
              data-aos-delay={100}
              src={img3}
              className={Styles.creditCard}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
