import React from "react";
import ExploreSection from "../components/sections/homepage/ExploreSection";
import HeroSection from "../components/sections/homepage/HeroSection";
import WhyPayInvert from "../components/sections/homepage/WhyPayInvert";

function HomePage() {
  return (
    <>
      <HeroSection />
      <WhyPayInvert />
      <ExploreSection />
    </>
  );
}
export default HomePage;
