import React from "react";
import HeroSection from "../../components/sections/payments/HeroSection";
import Checkout from "../../assets/checkout.png";
import CreditCard from "../../assets/credit-card-payment.png";
import CreditCardImg from "../../assets/credit-wallet.png";
import Styles from "./payments.module.scss";
import Features from "../../components/sections/payments/Features";
import DashboardFeatures from "../../components/sections/payments/DashboardFeatures";

export default function Payments() {
  return (
    <>
      <div className={Styles.heroWrapper}>
        <HeroSection
          title="A complete suite of payment technologies"
          desc="Our payments infrastructure is the technology you need, the sort of unified commerce that accelerates and sustains business growth. With PayInvert Payments, you can start accepting payments now online and offline."
          img1={Checkout}
          img2={CreditCard}
          img3={CreditCardImg}
        />
      </div>
      <Features />
      <DashboardFeatures />
    </>
  );
}
